.privacy-policy {
  max-width: 800px;
  margin: 26px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: black;
}

.policy-container {
  text-align: left;
}

.privacy-policy h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.privacy-policy p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 15px;
}

.privacy-policy a {
  color: #007bff;
  text-decoration: none;
}

.privacy-policy a:hover {
  text-decoration: underline;
}
