.footer {
  display: flex;
  justify-content: center;
  min-height: 200px;
  background-color: #f2f2f2;
  width: 100%;
  padding: 32px 16px;
  font-family: "Sen";
}

.footer-content-container {
  display: flex;
  width: 60%;
}

.footer-column {
  display: flex;
  flex-direction: column;
  margin: 0px 32px;
  align-items: flex-start;
}

.footer-link {
  cursor: pointer;
  color: black;
  opacity: 60%;
}

@media (max-width: 768px) {
  .footer-content-container {
    width: 80%;
    flex-direction: column;
  }

  .footer-column {
    margin-bottom: 32px;
  }
}

@media (max-width: 1800px) {
  .footer-content-container {
    width: 80%;
  }
}
