.commands-topbar {
  display: flex;
  width: 100%;
  border-bottom: 4px solid #fff;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}

.topbar-header {
  margin: 4px 0px;
}

.login-form-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.add-command {
  background: linear-gradient(to right, #a18eed, #77f7c3);
  border: 0px;
  font-weight: bold;
  color: #191825;
  padding: 4px 16px;
  border-radius: 8px;
  cursor: pointer;
}

.login-form {
  width: 100%;
}
