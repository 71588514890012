@import url("https://fonts.googleapis.com/css?family=MuseoModerno");
@import url("https://fonts.googleapis.com/css?family=Sen");
@import "typeface-roboto";

body {
  font-family: "MuseoModerno";
}

button {
  font-family: "MuseoModerno";
}

.ekorn-gradient {
  background: linear-gradient(to right, #a18eed 0%, #76f8c3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ekorn-gradient-alter {
  background: linear-gradient(to right, #a18eed 0%, #76f8c3 20%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ekorn-gradient-vertical {
  background: linear-gradient(to top, #a18eed 0%, #76f8c3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.App {
  text-align: center;
  background-color: #191825;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.base-page-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding: 32px;
}

.base-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

@media (max-width: 768px) {
  .base-page,
  .base-page-header {
    width: 80%;
  }
}

@media (min-width: 768px) {
  .base-page,
  .base-page-header {
    width: 80%;
  }
}

@media (min-width: 1800px) {
  .base-page,
  .base-page-header {
    width: 60%;
  }
}

a {
  color: #51b7d3;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pricing-content {
  min-height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cta-button {
  font-size: 16px;
  display: inline-block;
  padding: 10px 80px;
  margin: 16px 0px;
  color: #fff;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.cta-button:hover {
  background-color: #0056b3;
}

.success-message {
  color: rgb(160, 209, 160);
  margin-top: 10px;
}

.error-message {
  color: rgb(209, 157, 157);
  margin-top: 10px;
}

p {
  font-size: 14px;
  font-family: "Sen", cursive;
}
