.primary-cta {
  min-width: 62px;
  background: linear-gradient(to right, #a18eed, #77f7c3);
  border: 0px;
  font-weight: bold;
  color: #191825;
  padding: 14px 24px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-family: "Sen";
}

.secondary-cta {
  min-width: 62px;
  background-color: #191825;
  font-weight: bold;
  padding: 12px 24px;
  border: 0px;
  border-radius: 8px;
  cursor: pointer;
  color: #ffffff;
  font-size: 16px;
  font-family: "Sen";
  width: 100%;
}

.menu-cta {
  background: linear-gradient(to right, #a18eed, #77f7c3);
  border: 0px;
  font-weight: bold;
  color: #191825;
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-family: "Sen";
}

.rounded-button-cta {
  background: linear-gradient(to right, #a18eed, #77f7c3);
  border: 0px;
  font-weight: bold;
  color: #191825;
  padding: 8px 12px;
  border-radius: 32px;
  cursor: pointer;
  font-size: 16px;
  font-family: "Sen";
}

.rounded-button-cta.outline {
  background: #191825;
  color: white;
}

@media (min-width: 768px) {
  .primary-cta {
    font-size: 18px;
    min-width: 180px;
  }
  .secondary-cta {
    font-size: 18px;
    min-width: 180px;
  }
}

.outline-gradient-div {
  display: flex;
  text-align: center;
  border-radius: 8px;
  padding: 2px;
  background: linear-gradient(to right, #a18eed, #77f7c3);
  color: white;
  cursor: pointer;
}

.outline-gradient-div.rounded {
  border-radius: 32px;
}
