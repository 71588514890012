.book-meeting-container {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.book-meeting-container h1,
.book-meeting-container h2,
.book-meeting-container h3,
.book-meeting-container h4 {
  font-family: "Sen";
}

.book-meeting-title {
  margin-top: 60px;
  margin-bottom: 0px;
  font-size: 40px;
  font-weight: 100;
}

.book-meeting-subtitle {
  margin-top: 14px;
  margin-bottom: 76px;
  font-size: 26px;
  font-weight: 100;
  color: rgba(255, 250, 250, 0.7);
}

@media (max-width: 768px) {
  .book-meeting-subtitle {
    margin-top: 14px;
    margin-bottom: 50px;
    font-size: 20px;
  }

  .book-meeting-title {
    font-size: 30px;
  }
}
.book-meeting-cards-container {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 0px 0px;
  margin-bottom: 64px;
}

.book-meeting-card {
  display: flex;
  background-color: white;
  color: rgba(0, 0, 0, 0.8);
  border-radius: 20px;
  padding: 24px 36px;
  margin: 0px 36px;
  cursor: pointer;
  text-align: left;
  min-width: 260px;
}

.book-meeting-profile-pic {
  height: 70px;
  border-radius: 50%;
  margin-right: 26px;
}

.book-meeting-card:hover {
  opacity: 80%;
  transition: opacity 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .book-meeting-cards-container {
    flex-direction: column;
    align-items: center;
  }

  .book-meeting-cards-container p {
    font-size: 12px;
  }

  .book-meeting-card {
    padding: 10px 20px;
    margin: 0px;
    margin-bottom: 16px;
    min-width: 0px;
    max-width: 225px;
  }

  .book-meeting-profile-pic {
    height: 40px;
    margin-right: 12px;
  }
}

.book-meeting-card-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
