.product-benefits-container {
  display: flex;
  flex-direction: column;
  justify-items: center;
}

.product-benefits-integrations {
  display: flex;
}

.product-benefits-integrations-img {
  height: 300px;
}

.product-benefits-column {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 36px;
}

.product-benefits-column.left {
  justify-content: flex-end;
}

.product-benefits-column.right {
  justify-content: left;
}

.product-benefits-text {
  max-width: 400px;
  text-align: left;
  font-weight: 100;
}

@media (max-width: 768px) {
  .product-benefits-integrations {
    flex-direction: column;
  }

  .product-benefits-integrations-img {
    height: 200px;
  }

  .product-benefits-column {
    padding: 16px 0px;
    justify-content: center;
  }

  .product-benefits-text {
    font-size: 1em;
  }
}
