.add-popup form {
  display: flex;
  flex-direction: column;
  width: 500px;
}

.popup-input-container {
  display: flex;
  align-items: center;
  height: 35px;
}

.add-popup input,
.add-popup select {
  flex: 50%;
  max-height: 28px;
  padding: 5px;
  border-radius: 4px;
  margin-right: 18px;
}

.add-popup input:disabled {
  color: gray;
  background-color: rgb(185, 185, 185);
}

.add-popup button {
  margin-top: 10px;
  padding: 8px 12px;
  color: #fff;
  border: none;
  cursor: pointer;
}

.add-popup p {
  flex: 20%;
  font-size: 14px;
  text-align: left;
}

.popup-tooltip {
  font-size: 12px;
  margin-left: 4px;
  color: #ffffffd6;
}
