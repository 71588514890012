.product-demo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 50px 0px;
}

.product-demo-video {
  width: 60%;
}

@media (max-width: 768px) {
  .product-demo-video {
    width: 80%;
  }
}

.product-demo-video-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 2;
  transition: opacity 0.2s ease-in-out;
}

.product-demo-video-play-button:hover {
  opacity: 70%;
}

.product-demo-video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.6
  ); /* Adjust the background color and opacity as needed */
  z-index: 1; /* Place it behind the button */
}

.product-demo-bubble {
  position: absolute;
  height: 400px;
  top: 0px;
  right: 0px;
  z-index: 0;
}
