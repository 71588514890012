.contact-us-popup-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
}

.contact-us-popup-form input,
.contact-us-popup-form textarea {
  min-width: 300px;
  margin-bottom: 12px;
  padding: 8px;
  background-color: rgba(217, 217, 217, 0.34);
  border-radius: 8px;
  border: none;
  font-family: "Sen";
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
}

.contact-us-popup {
  display: flex;
  background-color: white;
  color: #191825;
  font-family: "Sen";
  padding: 8px 16px;
  justify-content: center;
}

.contact-us-popup-content {
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.contact-us-popup-title {
  margin-bottom: 16px;
}

.contact-us-popup-subtitle {
  margin: 0px;
  margin-bottom: 16px;
  opacity: 60%;
  font-size: 16px;
}

.contact-us-popup-form-message {
  height: 100px;
}

.contact-us-success-message {
  color: #a18eed;
  margin-top: 10px;
}
