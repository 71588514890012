.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.popup {
  background-color: #1e1e1e;
  padding: 20px;
  border-radius: 20px;
  text-align: center;
  z-index: 5;
}

.popup h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.popup-button {
  width: 90%;
  margin: 0 5px;
  padding: 8px 16px;
  background-color: #cccccc3d;
  color: #fff;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;
}

.popup-button:hover {
  background-color: #0056b3;
}
