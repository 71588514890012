.faq-list-container {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.faq-list-container h1,
.faq-list-container h2,
.faq-list-container h3,
.faq-list-container h4 {
  font-family: "Sen";
}

.faq-title {
  margin-top: 100px;
  margin-bottom: 0px;
  font-size: 60px;
  font-weight: 100;
}

.faq-subtitle {
  margin-top: 20px;
  margin-bottom: 76px;
  font-size: 26px;
  font-weight: 100;
  color: rgba(255, 250, 250, 0.7);
}

@media (max-width: 768px) {
  .faq-title {
    font-size: 50px;
  }
  .faq-subtitle {
    font-size: 20px;
    margin-bottom: 50px;
  }
}

.faq-question-container {
  display: flex;
  flex-direction: column;
}

.faq-question-row {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  text-align: left;
}

.faq-question-container p {
  text-align: left;
}

.faq-answer {
  font-size: 18px;
  color: rgba(255, 250, 250, 0.7);
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .faq-answer {
    font-size: 16px;
  }
}

.faq-answer.open {
  max-height: 1000px;
  transition: max-height 1s ease-in-out;
}

.faq-answer span.ekorn-gradient {
  font-weight: 900;
}

.faq-answer span.bold {
  font-weight: 900;
}

.faq-question-icon-container {
  display: inline-flex;
  align-items: center;
}

.faq-question-icon {
  font-size: 24px;
  margin-left: 10px;
  transition: transform 0.3s ease-in-out;
  transform: rotate(90deg);
}

.faq-question-icon.open {
  transform: rotate(180deg);
}
