.search-container {
  width: 80%;
}

.results-container {
  width: 100%;
  max-width: 800px;
  text-align: left;
  display: inline;
}

.search-box-container {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 16px 0px;
  margin-top: 24px;
}

.ais-SearchBox,
.search-box {
  display: flex;
  width: 100%;
}

.ais-SearchBox-form {
  border-radius: 5px 0 0 5px;
  border: none;
}

.search-button {
  padding: 0px 16px;
  border-radius: 0 5px 5px 0;
  border: none;
  background-color: #007bff;
  color: white;
  height: 2.5rem;
  cursor: pointer;
}

.ais-RefinementList-list {
  display: flex;
  color: white;
}

.ais-RefinementList-item {
  margin-right: 8px;
}
