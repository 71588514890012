.search-result {
  display: flex;
  cursor: pointer;
}

.result-image {
  width: 30px;
  height: 30px;
  object-fit: cover;
  margin-right: 12px;
}

.result-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.result-title {
  font-size: 18px;
  font-weight: 500;
  height: 30px;
  display: flex;
  align-items: center;
}

.result-description {
  margin: 4px 0;
  font-size: 14px;
  color: #2c2c2c;
}
