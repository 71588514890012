.video-circle-container {
  position: relative;
  align-self: flex-start;
}

.video-circle {
  width: 600px;
  height: 600px;
  top: -100px;
  left: -200px;
}

@media (max-width: 768px) {
  .video-circle {
    width: 250px;
    height: 250px;
    top: -35px;
    left: -100px;
  }
}

@media (min-width: 1800px) {
  .video-circle {
    width: 800px;
    height: 800px;
    top: -100px;
    left: -200px;
  }
}

.video-player {
  width: 100%;
  height: auto;
  border-radius: 20px;
}

.first-page-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  min-height: 80vh;
}

@media (max-width: 768px) {
  .first-page-container {
    min-height: 70vh;
  }
}

@media (max-width: 300px) {
  .first-page-container {
    min-height: 80vh;
  }
}

.first-page-container.landing {
  position: relative;
  justify-content: center;
  z-index: 1;
}

.first-page-container p,
.first-page-container h1,
.first-page-container h2,
.first-page-container h3,
.first-page-container h4 {
  font-family: "Sen";
}

.landing-page-tagline {
  display: flex;
  flex-direction: column;
}

.landing-page-tagline p,
.landing-page-tagline h1,
.landing-page-tagline h2,
.landing-page-tagline h3,
.landing-page-tagline h4 {
  font-weight: 100;
}

@media (max-width: 768px) {
  .landing-page-tagline h3 {
    font-size: 1.1em;
  }
}

.landing-page-search-bar {
  display: flex;
  border: 2px solid white;
  width: 60%;
  border-radius: 46px;
  padding: 12px 16px;
}

@media (max-width: 1400px) {
  .landing-page-search-bar {
    width: 80%;
  }
}

.magnifying-icon {
  color: white;
  height: 36px;
  align-self: center;
  margin-left: 16px;
  margin-right: 32px;
  margin-top: 4px;
}

@media (max-width: 1100px) {
  .landing-page-search-bar {
    width: 100%;
  }

  .magnifying-icon {
    height: 28px;
    margin-right: 18px;
  }
}

@media (max-width: 390px) {
  .landing-page-search-bar {
    width: 100%;
    padding: 8px 4px;
  }

  .magnifying-icon {
    height: 16px;
    margin-right: 6px;
    margin-left: 6px;
    margin-top: 2px;
  }
}

@media (max-width: 300px) {
  .landing-page-search-bar {
    padding: 4px 2px;
  }

  .magnifying-icon {
    height: 12px;
    margin-right: 4px;
    margin-left: 4px;
    margin-top: 1px;
  }
}

.animated-section {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.entry-animation {
  opacity: 1;
  transform: translateY(0);
}
