.pricing-page-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  align-items: center;
}

@media (max-width: 1400px) {
  .pricing-page-container {
    width: 100%;
  }
}

.pricing-page-title-container h1,
.pricing-page-title-container h2,
.pricing-page-title-container h3,
.pricing-page-title-container h4 {
  font-family: "Sen";
}

.pricing-page-title {
  margin-top: 46px;
  margin-bottom: 0px;
  font-size: 56px;
  font-weight: 100;
}

.pricing-page-subtitle {
  margin-top: 36px;
  margin-bottom: 125px;
  font-size: 26px;
  font-weight: 100;
  color: rgba(255, 250, 250, 0.7);
}

@media (max-width: 768px) {
  .pricing-page-title {
    font-size: 30px;
  }
  .pricing-page-subtitle {
    font-size: 16px;
    margin-bottom: 76px;
  }
}
