.product-details-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: "Sen";
}

.product-details-section-container {
  margin-bottom: 100px;
  position: relative;
}

.product-details-section {
  position: relative;
  display: flex;
  z-index: 1;
}

.product-details-section.reversed {
  flex-direction: row-reverse;
}

.product-details-column {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}

.product-details-column.reversed {
  flex-direction: row-reverse;
}

.product-details-examples-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.product-details-examples-container.reversed {
  align-self: start;
}

.product-details-browser-container {
  display: flex;
  justify-content: flex-end;
}

.product-details-browser {
  display: flex;
  background-color: #d9d9d9;
  border-radius: 12px;
  padding: 0px 14px;
  align-items: center;
}

.product-details-browser-circles {
  background-color: #808080;
  height: 7px;
  width: 7px;
  border-radius: 50%;
  margin-right: 4px;
}

.product-details-browser-input-text {
  color: #191825cc;
  background-color: white;
  font-weight: 900;
  border-radius: 40px;
  min-width: 275px;
  margin: 8px 10px;
  margin-right: 0px;
  text-align: left;
  padding: 4px 12px;
  font-family: "Roboto";
  font-size: 1.3em;
}

@media (max-width: 768px) {
  .product-details-section {
    flex-direction: column;
  }

  .product-details-examples-container {
    margin-bottom: 16px;
  }

  .product-details-browser-container {
    justify-content: flex-start;
  }
}
@media (max-width: 300px) {
  .product-details-browser-input-text {
    min-width: 180px;
    font-size: 1em;
  }
}

.product-details-explanation-container {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  text-align: left;
  height: 100%;
  justify-content: center;
}

.product-details-explanation-container.reversed {
  align-self: end;
}

.product-details-social-proof {
  display: flex;
  justify-content: center;
  margin: 100px 0px;
}

.product-details-social-proof-profile-pic-container {
  position: relative;
}

.product-details-social-proof-profile-pic {
  position: relative;
  border-radius: 50%;
  height: 200px;
}

.product-details-social-proof-background {
  position: absolute;
  transform: translate(-50%, -50%);
  height: 400px;
  top: 70px;
  left: 40px;
}

.product-details-social-proof-quote-container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  justify-content: center;
}

.product-details-social-proof-quotes {
  position: absolute;
  top: -30px;
  left: -30px;
}

.product-details-social-proof-quote-container h3 {
  text-align: left;
  font-weight: 100;
  margin: 0px;
}

@media (max-width: 768px) {
  .product-details-social-proof-quote-container h3 {
    font-size: 1em;
  }

  .product-details-social-proof-profile-pic {
    height: 100px;
  }

  .product-details-social-proof-background {
    height: 200px;
  }

  .product-details-social-proof-quote-container {
    margin-left: 16px;
  }
}

@media (max-width: 300px) {
  .product-details-social-proof-profile-pic {
    height: 75px;
  }
  .product-details-social-proof-quote-container h3 {
    font-size: 0.8em;
  }
}
