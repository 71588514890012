.story-container {
  font-family: "Sen";
  max-width: 800px;
  text-align: left;
  margin-bottom: 100px;
}

.story-container h1 {
  margin-bottom: 60px;
  text-align: center;
}

.story-container h2 {
  margin-top: 40px;
}

.story-container p {
  font-size: 16px;
}

.story-signature {
  margin-top: 40px;
  font-style: italic;
}
