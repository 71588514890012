.landing-page-animation-text {
  margin: 0px;
  text-align: left;
  font-family: "Sen";
}

@media (min-width: 768px) {
  .landing-page-animation-text {
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  .landing-page-animation-text {
    width: 110%;
  }
}

@media (max-width: 390px) {
  .landing-page-animation-text {
    font-size: 20px;
  }
}

@media (max-width: 300px) {
  .landing-page-animation-text {
    font-size: 16px;
  }
}
