.custom-table a {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.custom-table a,
.custom-table a:hover {
  text-decoration: none !important;
}

.custom-table {
  min-width: 900px;
  min-height: 500px;
  font-size: 18px;
  font-family: "Sen";
}

.custom-table thead tr,
.custom-table thead th {
  padding-bottom: 20px;
  border-top: none;
  border-bottom: none !important;
  color: #fff;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}
.custom-table tfoot th,
.custom-table tfoot td,
.custom-table tbody th,
.custom-table tbody td {
  color: #a1a1a1;
  font-weight: 400;
  font-weight: 300;
  border: none;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  padding: 4px 16px;
}
.custom-table tfoot th small,
.custom-table tfoot td small,
.custom-table tbody th small,
.custom-table tbody td small {
  color: #a1a1a1;
  font-weight: 300;
}
.custom-table tbody th a,
.custom-table tbody td a {
  color: #a1a1a1;
}
.custom-table tbody th .more,
.custom-table tbody td .more {
  color: #a1a1a1;
  font-size: 11px;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}
.custom-table tfoot tr,
.custom-table tbody tr {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.custom-table tbody tr:hover td,
.custom-table tbody tr:focus td {
  color: #fff;
}
.custom-table tbody tr:hover td a,
.custom-table tbody tr:focus td a {
  color: #fdd114;
}
.custom-table tbody tr:hover td .more,
.custom-table tbody tr:focus td .more {
  color: #fdd114;
}
.custom-table .td-box-wrap {
  padding: 0;
}
.custom-table .box {
  background: #fff;
  border-radius: 4px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.custom-table .box td,
.custom-table .box th {
  border: none !important;
}

.url-column {
  text-align: left;
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-column {
  text-align: left;
}

.custom-table form {
  display: flex;
  justify-content: space-between;
}

.form-horizontal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-input,
.commands-button[type="submit"] {
  padding: 4px 8px;
  border-radius: 4px;
  border: none;
  background-color: #666;
  color: #fff;
}

.custom-input {
  flex-grow: 1;
}

.custom-input::placeholder {
  color: lightgray;
}

.commands-button[type="submit"] {
  margin-left: 8px;
  cursor: pointer;
}

.commands-button[type="submit"]:hover {
  background-color: #888;
}

.actions-cell {
  display: flex;
  align-items: center;
}
