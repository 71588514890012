.feature-list {
  text-align: left;
}

.feature-list p {
  margin: 10px 0px;
  font-size: 12px;
  font-weight: 900;
  color: #191825;
}

@keyframes rotateGradient {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.rotating-text {
  background: linear-gradient(90deg, #9933ff, #77F7C3, #9933ff);
  background-size: 1000% 100%; /* Adjust background-size for smoother transition */
  animation: rotateGradient 3s linear infinite;
  -webkit-background-clip: text;
  color: transparent;
  text-fill-color: transparent; /* For Firefox support */
  margin: 0px;
}
