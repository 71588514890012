.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  width: 100%;
  z-index: 2;
}

.brand-logo {
  height: 28px;
  margin-bottom: -1px;
  margin-right: 6px;
}

.brand-name {
  font-size: 28px;
  font-weight: bold;
  font-family: "MuseoModerno";
  cursor: pointer;
}

.buttons {
  display: flex;
}

.page-button {
  color: #fff;
  font-size: 16px;
  margin-right: 10px;
  font-weight: bold;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.menu-cta-button {
  border: none;
  background-color: transparent;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  color: #191825;
  font-size: xx-large;
}

.menu-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: white;
  font-family: "Sen";
  padding: 0px;
  margin: 0px 20px;
  font-size: 16px;
}

.menu-button:hover {
  opacity: 70%;
  transition: opacity 0.15s ease-in-out;
}

.burger-button {
  border: none;
  background-color: #51b7d3;
  font-weight: bold;
  color: #fff;
  padding: 10px 13px;
  border-radius: 8px;
  cursor: pointer;
}

.right-section {
  position: relative;
  display: flex;
  align-items: center;
}

.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  background-color: #1e1e1e;
  width: 200px;
  height: 100vh;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media (min-width: 768px) {
  .sidebar {
    width: 20%;
  }
}

.sidebar button {
  margin-top: 10px;
  margin-left: 10px;
  padding: 5px;
}

.topbar-circle-container {
  position: relative;
  align-self: flex-start;
}

.menu-circle {
  position: absolute;
  width: 180px;
  height: 180px;
  top: 50%;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, -50%);
}

@media (max-width: 300px) {
  .menu-circle {
    width: 120px;
    height: 120px;
  }

  .menu-cta-button {
    font-size: 22px;
  }
}
