.instructions-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 5px;
  font-size: 18px;
}

.instructions-container-small {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 5px;
  font-size: 12px;
}

.instructions-list {
  list-style-position: inside;
  text-align: left;
  padding-left: 20px;
}

.instructions-list li {
  margin-top: 4px;
  text-indent: -1em;
  padding-left: 1em;
}

.instructions-list code {
  background-color: #f8f8f8;
  padding: 2px 4px;
  border-radius: 4px;
  font-family: Consolas, Monaco, 'Andale Mono', monospace;
  color: #1E1E1E;
}
