.search-box {
  width: 100%;
}

.search-box input[type="text"] {
  flex: 1;
  padding: 12px;
  border-radius: 5px 0 0 5px;
  border: none;
}

.search-box button[type="submit"] {
  padding: 12px 16px;
  border-radius: 0 5px 5px 0;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}
