.final-promo-container {
  display: flex;
  margin: 150px 0px;
}

.final-promo-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #302e40;
  border-radius: 20px;
  padding: 22px 26px;
  margin: 0px 16px;
}

.final-promo-card-left-section {
  text-align: left;
}

.final-promo-card-left-section h3 {
  margin: 0px;
}

.final-promo-card-left-section p {
  max-width: 200px;
  opacity: 70%;
}

.final-promo-card-img {
  height: 115px;
  margin-left: 16px;
}

@media (max-width: 768px) {
  .final-promo-container {
    flex-direction: column;
    margin: 100px 0px;
  }

  .final-promo-card-img {
    height: 75px;
  }

  .final-promo-card {
    margin: 16px 0px;
  }
}

@media (max-width: 300px) {
  .final-promo-card-img {
    height: 50px;
  }

  .final-promo-card-img {
    margin-left: 8px;
  }

  .final-promo-card {
    margin: 16px 0px;
  }
}
