.toggle-chip {
  display: inline-flex;
  align-items: center;
  padding: 6px 16px;
  border-radius: 32px;
  color: #fff;
  background-color: transparent;
  border: 2px solid #fff;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
}

.toggle-chip.active {
  background-color: #fff;
  color: #191825;
}

.icon {
  margin-right: 8px;
}

.text {
  font-size: 14px;
}

.toggle-chip:hover {
  opacity: 0.9;
}

.toggle-chip:hover * {
  opacity: 0.9;
}
