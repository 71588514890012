.about-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: "Sen";
  align-items: center;
  margin-top: 32px;
  margin-bottom: 80px;
  width: 60%;
}

.about-founder-cards-container {
  display: flex;
  margin-top: 80px;
  justify-content: center;
  width: 100%;
  justify-content: space-between;
}

.about-founder-container {
  display: flex;
}

.about-founder-container h3,
.about-founder-container h4,
.about-founder-container p {
  margin: 0px;
}

.about-founder-info {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  margin-left: 16px;
}

.about-founder-info-section {
  display: flex;
  flex-direction: column;
}

.about-founder-info-section.fav-shortcut {
  margin-top: 18px;
  opacity: 60%;
}

.about-founder-pic {
  height: 125px;
  border-radius: 50%;
}

.about-story {
  text-align: left;
  margin-top: 70px;
  font-size: 16px;
}

.about-linkedin-icon {
  color: #ffffff;
  cursor: pointer;
  margin-top: 1px;
  margin-left: 16px;
  font-size: 1.4em;
}

@media (max-width: 1400px) {
  .about-container {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .about-container {
    width: 100%;
  }
  .about-founder-container {
    display: flex;
    margin: 0px 0px;
    margin-bottom: 26px;
  }
  .about-founder-cards-container {
    flex-direction: column;
    align-items: center;
  }
  .about-story {
    font-size: 0.9em;
  }
  .about-founder-pic {
    height: 75px;
  }
  .about-linkedin-icon {
    margin-top: 2px;
    margin-left: 4px;
  }
  .about-founder-container h3 {
    font-size: 1em;
  }
  .about-founder-container h4 {
    font-size: 0.8em;
  }
  .about-founder-info-section.fav-shortcut {
    margin-top: 4px;
  }
}
