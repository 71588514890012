.text-input {
  max-width: 600px;
  margin: 0 4px;
  padding: 8px;
  border-radius: 5px;
  font-size: 20px;
}

.popup-button {
  width: 90%;
  margin: 0 5px;
  padding: 8px 16px;
  background-color: #cccccc3d;
  color: #fff;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;
}

.popup-button:hover {
  background-color: #0056b3;
}
