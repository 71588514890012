.new-pricing-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;
}

.new-pricing-container {
  display: flex;
  width: 60%;
  padding: 32px;
  border-radius: 16px;
  background-color: white;
}

@media (max-width: 768px) {
  .new-pricing-container {
    flex-direction: column;
  }
}

.new-pricing-price-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #191825;
}

.new-pricing-features-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .new-pricing-features-box {
    margin-top: 16px;
  }
}

.new-pricing-value {
  display: flex;
}

.new-pricing-sign {
  margin: 0px;
  font-size: 26px;
  color: gray;
  font-weight: 100;
  font-style: italic;
}

.new-pricing-digit {
  padding: 0px 8px;
  margin: 0px;
  font-size: 70px;
  line-height: 0.75em;
}

@media (max-width: 300px) {
  .new-pricing-digit {
    font-size: 40px;
  }
  .new-pricing-sign {
    font-size: 16px;
  }
  .new-pricing-asterisk {
    font-size: 16px;
  }
}

.new-pricing-asterisk {
  margin: 0px;
  font-size: 20px;
  color: gray;
  font-weight: 100;
}

.new-pricing-frequency {
  margin: 0px;
  color: gray;
  margin-top: 16px;
}

.new-pricing-small-print {
  margin: 0px;
  color: gray;
  font-size: 10px;
  font-weight: 600;
}

.new-pricing-cta-caption {
  margin: 0px;
  font-size: 12px;
}

.new-pricing-box-caption {
  font-size: 12px;
  font-weight: 600;
}
